<template>
    <div class="social-meeting-post">
        <div class="photo-container">
            <div v-if="eventType === 'friend_meeting' && !post.photo_url" class="empty-photo">
                친구끼리<br />
                {{ post.member / 2 }}:{{ post.member / 2 }}
            </div>
            <img v-else class="photo" v-lazy="post.photo_url" @error="$event.target.src = $blankProfile" />
            <img
                class="mini-photo"
                v-if="profileStyle !== 'privacy'"
                v-lazy="post.host_info.primary_photo"
                @error="$event.target.src = $blankProfile"
            />
        </div>
        <div class="content-container">
            <div v-if="type === 'all'" class="type-category-all">
                {{ isGroupEvent ? `회원끼리 (${post.member}명)` : `친구끼리 (${post.member / 2}:${post.member / 2})`
                }}{{ isGroupEvent ? `, ${post.category}` : '' }}
            </div>
            <div class="title" :class="type">
                <span class="title-word c-black m-r-8 lines-1" :class="{ isGroupEvent }">{{ post.title }}</span>
                <span class="f-13 c-grey-05" v-if="isGroupEvent">
                    {{ !post.chat_users ? `1/${post.member}` : `${post.chat_users}/${post.member}` }}
                </span>
            </div>
            <div class="date-region" :class="type" v-html="dateAndRegion" />
            <div v-if="type === 'all'" class="info-user">
                <div class="flex-row">
                    <div
                        class="gender flex-wrap"
                        :class="{ male: post.host_info.gender === 0, female: post.host_info.gender === 1 }"
                    />
                    <div
                        class="gender-text"
                        :class="{ male: post.host_info.gender === 0, female: post.host_info.gender === 1 }"
                    >
                        {{ post.host_info.gender === 0 ? '남, ' : '여, ' }}
                        {{
                            profileStyle === 'privacy'
                                ? $options.filters.blindAge(post.host_info.age)
                                : `${post.host_info.age}세`
                        }}
                    </div>
                </div>
            </div>
            <div class="interest" v-else>
                <div class="m-b-6">
                    <span class="type-category-other">
                        {{
                            isGroupEvent
                                ? `회원끼리 (${post.member}명)`
                                : `친구끼리 (${post.member / 2}:${post.member / 2})`
                        }}{{ isGroupEvent ? `, ${post.category}` : '' }}
                    </span>
                </div>
                <div class="status">
                    {{ statusString }}
                    <img
                        v-if="showNewBadge"
                        class="new-badge m-l-4"
                        src="@/assets/images/today-meeting/new_badge.png"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialMeetingPost',
    props: {
        post: Object,
        type: {
            type: String,
            required: true,
        },
        from: String,
    },
    data: () => ({
        dayOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
    }),
    computed: {
        dateAndRegion() {
            if (!this.post) return ''

            const state = this.post.state
            const region = this.post.region_detail

            const today = this.$moment().format('YYYY-MM-DD')
            const expireAt = this.$moment(this.post.expire_at)
            const diff = expireAt.diff(today, 'days')
            const month = (expireAt.month() + 1) % 13
            const date = expireAt.date()
            const day = expireAt.day()

            if (diff < 0) return `마감<span>ㅣ</span>${state}`
            else if (diff === 0) return `${month}.${date} (오늘)<span>ㅣ</span>${state} (${region})`
            else if (diff === 1) return `${month}.${date} (내일)<span>ㅣ</span>${state} (${region})`
            else return `${month}.${date} (${this.dayOfWeek[day]})<span>ㅣ</span>${state} (${region})`
        },
        profileStyle() {
            return this.post.profile_style
        },
        eventType() {
            return this.post.event_type
        },
        isGroupEvent() {
            return this.eventType === 'group_meeting'
        },
        statusString() {
            if (this.type === 'myPost') {
                return `${this.participants.length}명이 신청했어요!`
            } else if (this.type === 'interested') {
                if (!this.participant) return ''
                const { status } = this.participant

                if (status === 'request') return '호스트 확인 전'
                else if (status === 'checked') return '호스트 확인 완료'
                else if (status === 'accepted') {
                    if (this.post.event_type === 'group_meeting') return '단톡방 초대 완료'
                    else {
                        if (this.isPaid) {
                            if (this.isPaidBoth) {
                                return '연락처 교환 완료'
                            } else if (this.participant.host_paid) {
                                return '상대의 만남 수락'
                            } else if (this.participant.participant_paid) {
                                return '내가 만남 수락함'
                            }
                        } else return '프로필 확인 가능'
                    }
                }
            }
            return ''
        },
        regionString() {
            if (this.post.state === '서울' || this.post.state === '경기') {
                const regionDetail = this.$store.getters.eventRegions.find(
                    region => region.id === this.post.event_region_id,
                )
                if (regionDetail) {
                    return `${this.post.state}(${regionDetail.name})`
                } else {
                    return `${this.post.state}`
                }
            } else {
                return `${this.post.state}`
            }
        },
        participant() {
            // 내가 관심있는 포스트에 있음
            return this.post.participant
        },
        participants() {
            // 내가 만든 포스트에 있음
            return this.post.participants
        },
        isPaid() {
            return this.participant.participant_paid || this.participant.host_paid
        },
        isPaidBoth() {
            return this.participant.participant_paid && this.participant.host_paid
        },
        showNewBadge() {
            if (this.type === 'myPost') {
                return this.post.participants.some(p => !p.host_checked)
            }

            if (this.type === 'interested') {
                return !this.post.participant.participant_checked
            }

            return false
        },
    },
}
</script>

<style lang="scss" scoped>
.social-meeting-post {
    display: flex;
    flex-direction: row;
    height: 136px;
    padding: 16px 0;
    border-bottom: solid 1px $grey-01;

    .photo-container {
        position: relative;
        .photo {
            width: 104px;
            height: 104px;
            border-radius: 8px;
            border: solid 1px $grey-02;
        }
        .empty-photo {
            width: 104px;
            height: 104px;
            background-image: linear-gradient(315deg, $blue-facebook, #36b3ff);
            border-radius: 8px;
            border: solid 1px $grey-02;
            display: flex;
            justify-content: center;
            align-items: center;
            @include f-medium;
            font-size: 15px;
            color: white;
            text-align: center;
        }
        .mini-photo {
            position: absolute;
            right: 4px;
            bottom: 4px;
            width: 32px;
            height: 32px;
            border-radius: 12.8px;
            border: 1px solid rgba(233, 233, 237, 0.7);
        }
    }

    .content-container {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .type-category-all {
            font-size: 13px;
            @include f-bold;
            color: $blue-facebook;
            margin-bottom: 2px;
        }

        .title {
            font-size: 16px;
            @include f-medium;
            margin-bottom: 4px;
            display: flex;
            align-items: center;

            .title-word {
                max-width: calc(100vw - 104px - 48px);

                &.isGroupEvent {
                    max-width: calc(100vw - 104px - 48px - 32px);
                }
            }

            .new-badge {
                height: 15px;
            }

            &.all {
                margin-bottom: 10px;
            }
        }

        .date-region {
            font-size: 12px;
            @include f-medium;
            color: $grey-07;
            margin-bottom: 8px;

            ::v-deep span {
                color: $grey-04;
            }

            &.all {
                margin-bottom: 4px;
            }
        }

        .info-user {
            .gender {
                margin-right: 6px;
                width: 10px;
                height: 10px;
                border-radius: 40%;
                align-self: center;

                &.male {
                    background-color: $blue-man;
                    color: $blue-man;
                }

                &.female {
                    background-color: $pink-woman;
                    color: $pink-woman;
                }
            }

            .gender-text {
                font-size: 12px;
                @include f-bold;

                &.male {
                    color: $blue-man;
                }

                &.female {
                    color: $pink-woman;
                }
            }
        }

        .type-category-other {
            font-size: 12px;
            @include f-medium;
            color: $grey-09;
            background-color: $grey-02;
            border-radius: 6px;
            padding: 2px 8px;
            white-space: nowrap;
        }

        .status {
            font-size: 12px;
            color: $blue-facebook;
            @include f-bold;
            display: flex;
            align-items: center;

            .new-badge {
                height: 18px;
            }
        }

        .interest {
            @include flex;
            justify-content: space-between;
        }
    }
}
</style>
